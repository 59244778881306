import React, { useCallback, useEffect, useRef, useState } from "react";
import { DEEP_OCEAN_BLUE, INHERIT, SMALL, START } from "../../utils/constants";
import {
  Backdrop,
  CircularProgress,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ReportsTable from "../Common/ReportsTable";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { fetchProductListingReport } from "../../services/reports";
import { enqueueSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

const BuySellListing = () => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [products, setProducts] = useState([]);
  const searchValuesRef = useRef(searchValue);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const rowsPerPageRef = useRef(rowsPerPage);
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get("from");
  const to = queryParams.get("to");
  const listingCount = queryParams.get("totalRecords");

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [setPage]);

  const fetchData = useCallback(
    async (pageVal) => {
      dispatch(startLoading());
      const response = await fetchProductListingReport(
        id,
        from,
        to,
        searchValuesRef.current,
        pageVal || 0,
        rowsPerPage,
        sortColumn,
        sortBy
      );
      if (response.success && response?.data) {
        setTotalRecords(listingCount || 0);
        setProducts(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [dispatch, id, from, to, sortBy, sortColumn, rowsPerPage, listingCount]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchListings = async () => {
      setPage(0);
      dispatch(startLoading());
      const response = await fetchProductListingReport(
        id,
        from,
        to,
        searchValue,
        0,
        rowsPerPageRef.current,
        sortColumn || "",
        sortBy || "asc"
      );
      if (response.success && response.data) {
        setTotalRecords(listingCount || 0);
        setProducts(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchListings();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, id, sortBy, sortColumn, from, to, listingCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const loading = useSelector((state) => state.common.loading);
  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <CircularProgress color={INHERIT} />
        </Backdrop>
      </div>
      <Paper className="container" elevation={4}>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item md={6}>
            <OutlinedInput
              fullWidth
              placeholder="Search by title and description"
              size={SMALL}
              onChange={(e) => setSearchValue(e.target.value)}
              startAdornment={
                <InputAdornment position={START}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <ReportsTable
          from="buySellListing"
          headers={[
            { label: "Title" },
            { label: "Description" },
            { label: "Category" },
            { label: "Status" },
            {
              label: "# of Unique Views",
              isSortable: true,
              sortKey: "UniqueViews",
            },
            {
              label: "# of MSG. initiations",
              isSortable: true,
              sortKey: "messageInitiations",
            },
          ]}
          data={products?.map((row) => {
            return {
              id: row?.id,
              commonColumns: [
                row?.title || "-",
                row?.description || "-",
                row?.category || "-",
                row?.status || "-",
                row?.uniqueViews || "-",
                row?.messageInitiations || "-",
              ],
              data: row,
            };
          })}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          sortOrderHandler={sortOrderHandler}
        />
      </Paper>
    </>
  );
};

export default BuySellListing;
