import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Resident.scss";
import "../Common/Common.scss";
import { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import {
  fetchResident,
  updateResident,
  duplicatePhoneNumber,
  uploadImage,
  deleteResidentImage,
  canPrintPin,
  deleteResident,
} from "../../services/resident";
import { fetchAllCommunities } from "../../services/community";
import { fetchAllResidentStatus } from "../../services/resident-status";
import { fetchBuildingForCommunity } from "../../services/building";
import { fetchApartmentForBuilding } from "../../services/apartment";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import {
  BLACK,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  NAME_PREFIX_TYPES,
  RESIDENT_TYPES,
  ROW,
  SMALL,
  SOMETHING_WENT_WRONG,
} from "../../utils/constants";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  openConfirmationModal,
  openDeleteReasonModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import ImagePicker from "../ServiceProvider/ImagePicker";
import CommonValidations from "../Common/CommonValidations";
import ConfirmationModal from "../Common/ConfirmationModal";
import DeleteReasonModal from "../Common/DeleteReasonModal";
import { OpenPdfViewer } from "../../helpers/OpenPdfViewer";

const Edit = () => {
  const navigate = useNavigate();
  const [resident, setResident] = useState({});
  const [communities, setCommunities] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [phoneError, setPhoneError] = useState(false);
  const [deletedReason, setDeletedReason] = useState({});
  const [clickedButton, setClickedButton] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/resident/all"}
      className="breadcrumb__link"
    >
      Residents
    </Link>,
    <Typography key="3" color={BLACK}>
      Edit
    </Typography>,
  ];

  useEffect(() => {
    // Fetching communities
    const fetchCommunities = async () => {
      dispatch(startLoading());
      const communityResponse = await fetchAllCommunities();
      if (communityResponse.success && communityResponse.data) {
        setCommunities(communityResponse?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(communityResponse.data, { variant: "error" });
      }
    };
    fetchCommunities();
    // Fetching the resident
    const fetchResidents = async () => {
      const residentResponse = await fetchResident(id);
      if (residentResponse.success && residentResponse.data) {
        setResident(residentResponse?.data);
        if (residentResponse?.data?.resident?.community?.id) {
          // Fetching buildings based on community
          const buildingsResponse = await fetchBuildingForCommunity(
            residentResponse?.data?.resident?.community?.id
          );
          if (buildingsResponse.success && buildingsResponse.data) {
            setBuildings(buildingsResponse.data);
            const apartmentResponse = await fetchApartmentForBuilding(
              residentResponse?.data?.resident?.building?.id
            );
            if (apartmentResponse.success && apartmentResponse.data) {
              setApartments(apartmentResponse.data);
            } else {
              enqueueSnackbar(apartmentResponse.data, {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar(buildingsResponse.data, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar(SOMETHING_WENT_WRONG, { variant: "error" });
        }
      } else {
        enqueueSnackbar(residentResponse.data, { variant: "error" });
      }
    };
    dispatch(startLoading());
    fetchResidents();
    dispatch(stopLoading());
  }, [id, dispatch]);

  const getResidentStatus = useCallback(async () => {
    const response = await fetchAllResidentStatus();
    if (response.success && response.data) {
      setStatuses(response?.data);
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, []);

  useEffect(() => {
    getResidentStatus();
  }, [getResidentStatus]);

  const validationSchema = Yup.object({
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .test("phone-exists", "Phone number already exist", function (value) {
        return !phoneError;
      }),
    email: Yup.string()
      .email("Invalid email format")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
  }).concat(CommonValidations);

  const deleteHandler = async (values) => {
    dispatch(startLoading());
    const response = await deleteResident(
      resident?.resident?.id,
      values?.reason,
      values?.userRequested
    );
    navigate(`/admin/resident/all`);
    if (response.success && response.data) {
      dispatch(stopLoading());
      enqueueSnackbar(response.data.message, {
        variant: response.data.data.isDuplicatePhoneNumber
          ? "error"
          : "success",
      });
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const fetchDeletedReason = async (phoneNumber, onSuccess) => {
    dispatch(startLoading());
    const response = await canPrintPin(phoneNumber);
    if (response.success && response.data) {
      setDeletedReason(response.data);
      dispatch(stopLoading());
      onSuccess(response?.data);
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  // const clickHandler = () => {
  //   fetchDeletedReason(resident?.resident?.phone, (canPrint) => {
  //     if (canPrint?.reason) {
  //       dispatch(openConfirmationModal());
  //     } else {
  //       OpenPdfViewer("Resident Verification PIN", resident);
  //     }
  //   });
  // };

  const clickHandler = (event) => {
    const from = event.currentTarget.getAttribute("from");
    setClickedButton(from);
    if (from === "Only Pin Pdf") {
      fetchDeletedReason(resident?.resident?.phone, (canPrint) => {
        if (canPrint?.reason) {
          dispatch(openConfirmationModal());
        } else {
          OpenPdfViewer("Resident Verification Pin Only", resident);
        }
      });
    } else if (from === "Full Pdf") {
      fetchDeletedReason(resident?.resident?.phone, (canPrint) => {
        if (canPrint?.reason) {
          dispatch(openConfirmationModal());
        } else {
          OpenPdfViewer("Resident Verification Pin Full", resident);
        }
      });
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: resident?.resident?.firstName || "",
        lastName: resident?.resident?.lastName || "",
        phone: resident?.resident?.phone || "",
        email: resident?.resident?.email || "",
        community: resident?.resident?.community?.id || "",
        building: resident?.resident?.building?.id || "",
        apartmentId: resident?.resident?.apartment?.id || "",
        residentStatus: resident?.resident?.residentStatus?.id || "",
        namePrefix: resident?.resident?.namePrefix || "",
        residentType: resident?.resident?.residentType || "",
        image: resident?.image || "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        dispatch(startLoading());
        const result = await updateResident(id, values);
        if (result.success) {
          if (typeof values?.image === "string") {
            dispatch(stopLoading());
            enqueueSnackbar(result?.data, {
              variant: "success",
            });
            navigate(`/admin/resident/all`);
          } else if (values.image === null) {
            const response = await deleteResidentImage(id);
            if (response.success) {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data, {
                variant: "success",
              });
              navigate(`/admin/resident/all`);
            } else {
              enqueueSnackbar(response?.data, { variant: "error" });
              navigate(`/admin/resident/all`);
            }
          } else {
            const response = await uploadImage(values.image, id);
            if (response.success) {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data, {
                variant: "success",
              });
              navigate(`/admin/resident/all`);
            } else {
              enqueueSnackbar(response?.data, { variant: "error" });
              navigate(`/admin/resident/all`);
            }
          }
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
        errors,
        touched,
      }) => {
        const handleCommunityChange = async (event) => {
          const communityId = event.target.value;
          dispatch(startLoading());
          const buildingsResponse = await fetchBuildingForCommunity(
            communityId
          );
          if (
            buildingsResponse.success &&
            buildingsResponse?.data?.length > 0
          ) {
            setBuildings(buildingsResponse.data);
            setFieldValue("building", "");
            setFieldValue("apartmentId", "");
            dispatch(stopLoading());
          } else {
            dispatch(stopLoading());
            setBuildings([]);
            setApartments([]);
            setFieldValue("building", "");
            setFieldValue("apartmentId", "");
          }
        };

        const handleBuildingChange = async (event) => {
          const buildingId = event.target.value;
          dispatch(startLoading());
          const apartmentResponse = await fetchApartmentForBuilding(buildingId);
          if (
            apartmentResponse.success &&
            apartmentResponse?.data?.length > 0
          ) {
            setApartments(apartmentResponse.data);
            setFieldValue("apartmentId", "");
            dispatch(stopLoading());
          } else {
            dispatch(stopLoading());
            enqueueSnackbar(apartmentResponse.data, {
              variant: "error",
            });
            setApartments([]);
            setFieldValue("apartmentId", "");
          }
        };

        const handlePhoneNumberChange = async (e) => {
          setFieldValue("phone", e.target.value);
          if (resident?.resident?.phone !== e.target.value) {
            if (e.target.value?.length === 10) {
              const response = await duplicatePhoneNumber(e.target.value);
              if (response.success && response.data.isDuplicatePhoneNumber) {
                setPhoneError(true);
                setFieldError("phone", "Phone number already exist");
              } else {
                setPhoneError(false);
                setFieldError("phone", "");
              }
            } else {
              setPhoneError(false);
              setFieldError("phone", "");
            }
          } else {
            setPhoneError(false);
            setFieldError("phone", "");
          }
        };

        return (
          <Paper className="paper__container" elevation={4}>
            <form noValidate onSubmit={handleSubmit} className="form">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Stack spacing={3}>
                    <Breadcrumbs
                      separator={<NavigateNextIcon />}
                      aria-label="breadcrumb"
                    >
                      {breadcrumbs}
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Paper
                    elevation={4}
                    className="ResidentView__basic__details__container"
                  >
                    <ImagePicker
                      setFieldValue={setFieldValue}
                      defaultImage={values?.image}
                      from="resident"
                    />
                  </Paper>
                </Grid>
                <Grid item md={9}>
                  <Paper
                    className="ResidentView__basic__details__container"
                    elevation={4}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <TextField
                          required
                          label="First Name"
                          fullWidth
                          size={SMALL}
                          value={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="firstName"
                          InputLabelProps={{
                            shrink: values.firstName ? true : false,
                          }}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        ></TextField>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          required
                          label="Last Name"
                          fullWidth
                          size={SMALL}
                          value={values?.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="lastName"
                          InputLabelProps={{
                            shrink: values.lastName ? true : false,
                          }}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        ></TextField>
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          disabled
                          required
                          label="Country Code "
                          fullWidth
                          size={SMALL}
                          name="countryCode"
                          value={"+91"}
                        ></TextField>
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          disabled
                          required
                          label="Phone "
                          fullWidth
                          size={SMALL}
                          value={values?.phone}
                          onBlur={handleBlur}
                          onChange={handlePhoneNumberChange}
                          name="phone"
                          InputLabelProps={{
                            shrink: values?.phone ? true : false,
                          }}
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={touched.phone && errors.phone}
                        ></TextField>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          label="Email"
                          size={SMALL}
                          fullWidth
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          InputLabelProps={{
                            shrink: values.email ? true : false,
                          }}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        ></TextField>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="community-label" required>
                            Community
                          </InputLabel>
                          <Select
                            labelId="community-label"
                            label="Community"
                            onChange={(event) => {
                              handleChange(event);
                              handleCommunityChange(event);
                            }}
                            name="community"
                            value={values?.community || ""}
                            required
                          >
                            {communities.map((community) => (
                              <MenuItem key={community.id} value={community.id}>
                                {community.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="building-label" required>
                            Building
                          </InputLabel>
                          <Select
                            labelId="building-label"
                            label="Building"
                            value={values?.building || ""}
                            onChange={(event) => {
                              handleChange(event);
                              handleBuildingChange(event);
                            }}
                            name="building"
                            required
                          >
                            {buildings.map((building) => (
                              <MenuItem key={building.id} value={building.id}>
                                {building.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl size={SMALL} fullWidth>
                          <InputLabel id="apartment-label" required>
                            Apartment
                          </InputLabel>
                          <Select
                            labelId="apartment-label"
                            label="Apartment"
                            value={values?.apartmentId || ""}
                            onChange={handleChange}
                            name="apartmentId"
                            required
                          >
                            {apartments.length > 0 &&
                              apartments
                                .slice()
                                .sort(
                                  (a, b) =>
                                    a.apartmentNumber - b.apartmentNumber
                                )
                                .map((apartment) => (
                                  <MenuItem
                                    key={apartment.id}
                                    value={apartment.id}
                                  >
                                    {apartment.apartmentNumber}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {resident?.resident?.residentStatus?.id === 2 && (
                        <Grid item md={6}>
                          <FormControl size={SMALL} fullWidth>
                            <InputLabel id="status-label" required>
                              Status
                            </InputLabel>
                            <Select
                              labelId="status-label"
                              label="Status"
                              name="residentStatus"
                              value={values?.residentStatus || ""}
                              onChange={handleChange}
                              required
                            >
                              {statuses
                                .filter(
                                  (status) => status.id === 2 || status.id === 4
                                )
                                .map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <div className="ResidentView__section__divider">
                        <span className="ResidentView__section__divider__span">
                          Personal Information
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={4} display={FLEX} alignItems={CENTER}>
                      <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                        <span className={"custom-label-span"}>* </span>
                        Salutation
                      </Typography>
                    </Grid>
                    <Grid item md={8} display={FLEX} alignItems={CENTER}>
                      <FormControl>
                        <RadioGroup
                          row
                          onChange={handleChange}
                          name="namePrefix"
                        >
                          {NAME_PREFIX_TYPES.map((namePrefixType) => (
                            <FormControlLabel
                              key={namePrefixType}
                              value={namePrefixType}
                              control={<Radio />}
                              label={namePrefixType}
                              checked={values?.namePrefix === namePrefixType}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} display={FLEX} alignItems={CENTER}>
                      <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                        <span className={"custom-label-span"}>* </span>
                        Resident Type
                      </Typography>
                    </Grid>
                    <Grid item md={8} display={FLEX} alignItems={CENTER}>
                      <FormControl>
                        <RadioGroup
                          row
                          onChange={handleChange}
                          name="residentType"
                        >
                          {RESIDENT_TYPES.map((residentType) => (
                            <FormControlLabel
                              key={residentType}
                              value={residentType}
                              control={<Radio />}
                              label={residentType}
                              checked={values?.residentType === residentType}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  {(resident?.resident?.residentStatus?.name ===
                    "Awaiting resident code" ||
                    resident?.resident?.residentStatus?.name ===
                      "Resident code mailed") &&
                    !resident?.resident?.deleted && (
                      <Grid container spacing={2} marginBottom={4}>
                        <Grid item md={12}>
                          <div className="ResidentView__section__divider">
                            <span className="ResidentView__section__divider__span">
                              Pin Information
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          display={FLEX}
                          alignItems={CENTER}
                          marginTop={2}
                        >
                          <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                            Pin Number
                          </Typography>
                        </Grid>
                        <Grid item md={8} display={FLEX} alignItems={CENTER}>
                          <Typography>{resident?.pin}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  <Stack direction={ROW} spacing={2} justifyContent={CENTER}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit__button"
                    >
                      Submit
                    </Button>
                    {resident?.pin !== null &&
                      (resident?.resident?.residentStatus?.name ===
                        "Awaiting resident code" ||
                        resident?.resident?.residentStatus?.name ===
                          "Resident code mailed") && (
                        <>
                          <Button
                            type="button"
                            variant="contained"
                            className="submit__button"
                            onClick={clickHandler}
                            from="Full Pdf"
                          >
                            Print full pdf
                          </Button>
                          <Button
                            type="button"
                            variant="contained"
                            className="submit__button"
                            onClick={clickHandler}
                            from="Only Pin Pdf"
                          >
                            Print code and resident info
                          </Button>
                          <ConfirmationModal
                            onOkHandler={() =>
                              clickedButton === "Only Pin Pdf"
                                ? OpenPdfViewer(
                                    "Resident Verification Pin Only",
                                    resident
                                  )
                                : clickedButton === "Full Pdf"
                                ? OpenPdfViewer(
                                    "Resident Verification Pin Full",
                                    resident
                                  )
                                : ""
                            }
                            onCancelHandler={() => {
                              deletedReason?.reason &&
                                dispatch(openDeleteReasonModal());
                            }}
                            cancelText={deletedReason?.reason ? "Delete" : "No"}
                            okText={deletedReason?.reason ? "Print" : "Yes"}
                            phoneNo={resident?.resident?.phone}
                            reason={deletedReason?.reason}
                          />
                          <DeleteReasonModal
                            onOkHandler={deleteHandler}
                            onCancelHandler={() => {}}
                            cancelText="Cancel"
                            okText="Submit"
                          />
                        </>
                      )}
                  </Stack>
                </Grid>
              </Grid>
              <Loader />
            </form>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Edit;
