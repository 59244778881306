import React from "react";
import "./Home.scss";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import ConstructionIcon from "@mui/icons-material/Construction";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import PrintIcon from "@mui/icons-material/Print";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ReviewsRoundedIcon from "@mui/icons-material/ReviewsRounded";
import { Link } from "react-router-dom";
import { WHITE } from "../../utils/constants";

const Home = () => {
  return (
    <Box className="Home">
      <Grid container className="container Home__container">
        <Grid item className="Home__left__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/resident/all"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <GroupsIcon className="Home__item__icon"></GroupsIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Residents
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
        <Grid item className="Home__right__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/service-provider/all"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <ConstructionIcon className="Home__item__icon"></ConstructionIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Service Providers
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
        <Grid item className="Home__left__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/review-management/all"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <ReviewsRoundedIcon className="Home__item__icon"></ReviewsRoundedIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Reviews
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
        <Grid item className="Home__right__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/buy-sell/all"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <LocalMallIcon className="Home__item__icon"></LocalMallIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Buy & Sell
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
        <Grid item className="Home__right__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/reports/all"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <StickyNote2OutlinedIcon className="Home__item__icon"></StickyNote2OutlinedIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Reports
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
        <Grid item className="Home__right__items">
          <Paper
            square={false}
            elevation={2}
            className="Home__item__container"
            component={Link}
            to="/admin/printPin"
          >
            <div>
              <ListItem button className="Home__item__icon__container">
                <PrintIcon className="Home__item__icon"></PrintIcon>
              </ListItem>
              <ListItem button>
                <Typography
                  color={WHITE}
                  fontSize="20px"
                  fontWeight="700"
                  className="Home__item__text"
                >
                  Print Pin
                </Typography>
              </ListItem>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
