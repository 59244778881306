export const DEEP_OCEAN_BLUE = "#003350";
export const BLACK = "#000000DE";
export const WHITE = "#FFFFFF";
export const TEAL_BLUE = "#2D6788";
export const GOLD = "#FFD700";
export const NAME_PREFIX_TYPES = ["Mr", "Ms", "Mrs", "Dr", "Prof"];
export const RESIDENT_TYPES = ["Tenant", "Owner", "Non resident owner"];
export const REINSTATE_MESSAGE = "Are you sure you want to re-instate?";
export const APPROVE_MESSAGE =
  "Have you uploaded T&C for this service provider manually?";
export const HOMEBASED_MESSAGE =
  "Have you confirmed that the resident has the necessary approvals from association to run this business from home?";
export const REMOVE_MESSAGE = "Are you sure you want to delete?";
export const SUSPEND_MESSAGE = "Are you sure you want to suspend?";
export const LANGUAGE_MESSAGE = "Please select a language";
export const UNHIDE_MESSAGE = "Are you sure you want to unhide?";
export const HIDE_MESSAGE = "Are you sure you want to hide?";
export const RESIDENT_PROVIDER_FILTER = ["Yes", "No"];
export const RESIDENT_STATUS_FILTER = [
  "All",
  "Onboarding",
  "Approved",
  "Waitlisted",
];
export const DELETE_STATUS_FILTER = ["Yes", "No"];
export const ALL = "All";
export const BOTH = "Both";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const SERVICE_PROVIDER_TABS = [
  "Service Providers",
  "Service Categories",
];
export const SERVICE_PROVIDER_STATUS_FILTER = [
  "Pending",
  "Active",
  "Suspended",
];
export const RESIDENT_FILTER = ["Yes", "No"];
export const REVIEW_TYPE_FILTER = ["Anonymous", "Identified"];
export const REVIEW_STATE_FILTER = ["Not Flagged", "Flagged"];
export const REVIEW_STATUS_FILTER = ["Active", "Flagged"];
export const LISTING_FILTER = ["Free", "Paid"];
export const SOLD_FILTER = ["Sold", "Available"];
export const EXPIRED_FILTER = ["Active", "Expired"];
export const PREFERRED_NOTIFICATIONS = ["WhatsApp", "SMS"];
export const SERVICE_LOCATION = ["Premises", "Home"];
export const RESIDENT_TYPE_FILTER = ["Resident", "Non-Resident"];
export const CONDITION_FILTER = ["GOOD AS NEW", "MODERATE", "OLD"];
export const SOLID = "solid";
export const SERVICE_PROVIDER_CREATE = "serviceProviderCreate";
export const SERVICE_PROVIDER_EDIT = "serviceProviderEdit";
export const RED = "red";
export const IMAGE_PICKER_BORDER_COLOR = "rgba(0, 0, 0, 0.23)";
export const RESIDENT = "resident";
export const BUY_SELL_EDIT = "buySellEdit";
export const BUY_SELL_VIEW = "buySellView";
export const SMALL = "small";
export const FLEX = "flex";
export const TEXT_COLOR = "rgba(0, 0, 0, 0.70)";
export const CENTER = "center";
export const AUTO = "auto";
export const TOP = "top";
export const LEFT = "left";
export const UNSET = "unset";
export const BOTTOM = "bottom";
export const SPACE_BETWEEN = "space-between";
export const FLEX_START = "flex-start";
export const FLEX_END = "flex-end";
export const ROW = "row";
export const COLUMN = "column";
export const NO_WRAP = "nowrap";
export const INITIAL = "initial";
export const BUY_SELL = "buySell";
export const RESIDENT_LIST = "residentList";
export const RESIDENT_VIEW = "residentView";
export const SERVICE_PROVIDER_VIEW = "serviceProviderView";
export const REVIEW_LIST = "reviewList";
export const SERVICE_PROVIDER_LIST = "serviceProviderList";
export const SERVICE_PROVIDER_ENGAGEMENT = "serviceProviderEngagement";
export const FIT_CONTENT = "fit-content";
export const BOLD = "bold";
export const INHERIT = "inherit";
export const NONE = "none";
export const START = "start";
export const REGISTERED = "Registered";
export const AWAITING_OTP = "Awaiting phone OTP";
