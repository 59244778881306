import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "./Common.scss";
import sortAscIcon from "../../assets/images/asc_sort.png";
import sortDscIcon from "../../assets/images/dsc_sort.png";
import sortInactiveIcon from "../../assets/images/sort_grey.png";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  NO_WRAP,
  ROW,
  SERVICE_PROVIDER_ENGAGEMENT,
  TOP,
  WHITE,
} from "../../utils/constants";

const ReportsTable = (props) => {
  const {
    from,
    headers,
    data,
    totalRecords,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    noPagination,
    sortBy,
    sortColumn,
    sortOrderHandler,
    classes,
  } = props;

  return (
    <>
      <Table className="TableComp__table">
        <TableHead>
          <TableRow sx={{ whiteSpace: NO_WRAP }}>
            {headers.map((header) => (
              <TableCell
                key={header?.label}
                className={
                  header?.label === "Actions"
                    ? "TableComp__table__header__Actions"
                    : "TableComp__table__header"
                }
                width={
                  header?.width ? header?.width : `${100 / headers.length}%`
                }
              >
                {header.isSortable ? (
                  <TableSortLabel
                    active={sortColumn === header?.sortKey}
                    direction={sortColumn === header?.sortKey ? sortBy : "asc"}
                    onClick={() => sortOrderHandler(header?.sortKey)}
                    IconComponent={() => (
                      <img
                        src={
                          sortColumn === header?.sortKey && sortBy === "asc"
                            ? sortAscIcon
                            : sortColumn === header?.sortKey &&
                              sortBy === "desc"
                            ? sortDscIcon
                            : sortInactiveIcon
                        }
                        alt="Sort icon"
                        width={10}
                        height={10}
                      />
                    )}
                  >
                    {header?.label}
                  </TableSortLabel>
                ) : (
                  header?.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={CENTER} colSpan={headers?.length}>
                No records to show
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row, ind) => (
              <TableRow key={ind}>
                {row?.commonColumns.map((data, index) => (
                  <TableCell key={index} component="th" scope={ROW}>
                    <div className="TableComp__table__cell__Content">
                      <Tooltip title={data} placement={TOP} arrow>
                        <span
                          className={`desc-data ${
                            data >= 1 && (index === 6 || index === 7)
                              ? classes
                              : ""
                          }`}
                        >
                          {data}
                        </span>
                      </Tooltip>
                      {index === 0 &&
                        from === SERVICE_PROVIDER_ENGAGEMENT &&
                        row?.resident && (
                          <>
                            &nbsp;
                            <Badge
                              badgeContent={"R"}
                              sx={{
                                "& .MuiBadge-badge": {
                                  color: WHITE,
                                  backgroundColor: DEEP_OCEAN_BLUE,
                                  marginLeft: "5px",
                                },
                              }}
                            ></Badge>
                          </>
                        )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {!noPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default ReportsTable;
