import {
  Box,
  Button,
  Grid,
  Modal,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  ROW,
  SMALL,
} from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { useDispatch } from "react-redux";
import { updateServiceProviderReview } from "../../services/review-management";
import * as Yup from "yup";

const Edit = (props) => {
  const { openModal, setOpenModal, editData, setEditData, fetchData } = props;
  const dispatch = useDispatch();

  const cancelHandler = () => {
    setOpenModal(false);
    setEditData(null);
  };

  const validationSchema = Yup.object({
    description: Yup.string()
      .required("Description is required")
      .max(1000, "Description must be at most 1000 characters"),
  });

  return (
    <Formik
      initialValues={{
        reviewBy:
          editData?.resident?.firstName + " " + editData?.resident?.lastName ||
          "",
        description: editData?.description || "",
        reviewTo:
          editData?.serviceProvider?.firstName +
            " " +
            editData?.serviceProvider?.lastName || "",
        rating: editData?.rating || "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        dispatch(startLoading());
        const result = await updateServiceProviderReview(editData?.id, values);
        if (result.success) {
          fetchData();
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "success" });
          setOpenModal(false);
        } else {
          dispatch(stopLoading());
          enqueueSnackbar(result?.data, { variant: "error" });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
      }) => {
        return (
          <Modal
            open={openModal}
            disablePortal={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="DeleteReason__Modal">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align={CENTER}
              >
                Edit Review
              </Typography>
              <form onSubmit={handleSubmit} className="form">
                <Grid container spacing={2} paddingTop={"6px"}>
                  <Grid item md={12}>
                    <TextField
                      label="Review by"
                      fullWidth
                      size={SMALL}
                      value={values?.reviewBy}
                      onChange={handleChange}
                      name="reviewBy"
                      required
                      disabled
                    ></TextField>
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      label="Review To"
                      fullWidth
                      size={SMALL}
                      value={values?.reviewTo}
                      onChange={handleChange}
                      name="reviewTo"
                      required
                      disabled
                    ></TextField>
                  </Grid>
                  <Grid item md={12} height={165}>
                    <TextField
                      label="Description"
                      multiline
                      fullWidth
                      maxRows={5}
                      minRows={5}
                      size={SMALL}
                      value={values?.description}
                      onChange={handleChange}
                      name="description"
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    ></TextField>
                  </Grid>
                  <Grid item md={12} display={FLEX} alignItems={CENTER}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Rating
                    </Typography>
                    <Typography
                      color={DEEP_OCEAN_BLUE}
                      fontWeight={600}
                      paddingRight={"10px"}
                    >
                      :
                    </Typography>
                    <Rating
                      value={values?.rating}
                      onChange={handleChange}
                      precision={0.5}
                      name="rating"
                      readOnly
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Stack direction={ROW} spacing={2} justifyContent={CENTER}>
                      <Button
                        variant="outlined"
                        onClick={cancelHandler}
                        className="cancel__button"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className="submit__button"
                      >
                        Save and Publish
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default Edit;
