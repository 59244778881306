import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import loader from "../../assets/images/Loader.gif";
import {
  fetchServiceProviderByToken,
  acceptTermsAndConditions,
} from "../../services/service-provider";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  Card,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "./ServiceProviderTermsAndConditions.scss";
import NivasiDarkIcon from "../../utils/images/nivasi-icon-dark.png";
import PrivacyAndTermsIcon from "../../utils/images/privacy-and-terms.png";
import ThankYouIcon from "../../utils/images/thankyou.png";
import NivasiLightIcon from "../../utils/images/nivasi-icon-light.png";
import FooterImage from "../../utils/images/footer.png";
import Modal from "@mui/material/Modal";
import { CENTER, DEEP_OCEAN_BLUE, FLEX, SMALL } from "../../utils/constants";
import axios from "axios";

const ServiceProviderTermsAndConditions = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [serviceProvider, setServiceProvider] = useState(null);
  const [serviceProviderName, setServiceProviderName] = useState("");
  const [thankYouModal, setThankYouModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [status, setStaus] = useState(null);
  const [checked, setChecked] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [ip, setIp] = useState("");

  useEffect(() => {
    // Fetch the IP address
    axios.get("https://api.ipify.org?format=json").then((response) => {
      setIp(response.data.ip);
    });
  }, []);

  const handleOpen = async () => {
    const response = await acceptTermsAndConditions(token, ip);
    if (response.success && response.data) {
      setTermsAccepted(true);
    }
    setThankYouModal(true);
  };

  const handleClose = () => setThankYouModal(false);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderByToken(token);
      if (response.success && response.data) {
        const serviceProviderFromResponse = response?.data?.serviceProvider;
        setServiceProvider(serviceProviderFromResponse);
        setStaus(serviceProviderFromResponse.status);
        setServiceProviderName(serviceProviderFromResponse.name);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
      setSpinning(false);
    })();
  }, [dispatch, token, termsAccepted]);

  return (
    <React.Fragment>
      {!spinning ? (
        status === "Active" ? (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 500, overflowY: "auto" }}
          >
            <Grid container spacing={2} padding={2}>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <Container>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={7}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" className="privacy-terms-heading">
                      {serviceProviderName || "[service provider name]"},
                      <Typography className="thankyou-text">
                        Thank you for accepting and acknowledging our terms and
                        conditions. Your profile is now live on Nivasi app.
                        Residents interested in your services will reach out to
                        you at the phone number you provided.
                        <br />
                        <br />
                        To enhance your profile and make it appealing to more
                        residents, please request residents that availed your
                        service, to provide reviews of your services on the app.
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Paper>
        ) : status !== "Pending" ? (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid container spacing={2} padding={2}>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <Container>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={7}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" className="privacy-terms-heading">
                      <Typography className="thankyou-text">
                        Link Expired or Invalid
                      </Typography>
                      <Typography>
                        We apologize, but the link you accessed is no longer
                        valid or may have expired. If you are trying to accept
                        terms and conditions, and running into this error,
                        please reach out to our support team for further
                        assistance.
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Paper>
        ) : (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={9}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h4"
                      className={"privacy-terms-heading"}
                    >
                      Preview of Nivasi Profile for
                    </Typography>
                    <Typography className={"privacy-terms-name"}>
                      {serviceProviderName || "[service provider name]"}
                    </Typography>
                    <Typography
                      className="privacy-terms-date"
                      marginTop={2}
                      marginBottom={2}
                    >
                      Updated: 14 June 2024
                    </Typography>
                    <Grid>
                      <img
                        className="profile-image"
                        src={serviceProvider.image}
                        alt="profile"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} marginTop={5}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid marginTop={5} lineHeight={5}>
                  <Grid paddingBottom={1}>
                    <Grid>
                      <Typography fontWeight={"600"}>Contact Name:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>{serviceProvider?.name}</Typography>
                    </Grid>
                  </Grid>
                  {serviceProvider?.businessName && (
                    <Grid paddingBottom={1}>
                      <Grid>
                        <Typography fontWeight={"600"}>
                          Business Name:
                        </Typography>
                      </Grid>
                      <Grid item md={9}>
                        <Typography>{serviceProvider?.businessName}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid paddingBottom={1}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Phone:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>{serviceProvider?.phoneNumber}</Typography>
                    </Grid>
                  </Grid>
                  {serviceProvider?.email && (
                    <Grid paddingBottom={1}>
                      <Grid item md={3}>
                        <Typography fontWeight={"600"}>Email:</Typography>
                      </Grid>
                      <Grid item md={9}>
                        <Typography>{serviceProvider?.email}</Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid paddingBottom={1}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Location:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>{serviceProvider?.address}</Typography>
                    </Grid>
                  </Grid>
                  <Grid paddingBottom={1}>
                    <Grid>
                      <Typography fontWeight={"600"}>
                        Google Maps Link:{" "}
                      </Typography>
                    </Grid>

                    <Grid item md={9}>
                      <Typography sx={{ wordBreak: "break-word" }}>
                        <a href={serviceProvider?.locationUrl}>
                          {serviceProvider?.locationUrl}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid paddingBottom={1}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Title:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>{serviceProvider?.title}</Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>About:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography>
                        {serviceProvider?.aboutTheBusiness}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={5} mb={2} alignItems={CENTER} display={FLEX}>
                  <FormControl>
                    <FormControlLabel
                      key={"terms"}
                      control={
                        <Checkbox
                          sx={{
                            color: DEEP_OCEAN_BLUE,
                          }}
                          size={SMALL}
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                      }
                    />
                  </FormControl>
                  <Typography className="label__fontsize">
                    I agree to the{" "}
                    <a href="https://www.nivasiapp.com/service-provider/terms-of-service">
                      Terms of Service
                    </a>{" "}
                    of Nivasi.
                  </Typography>
                </Box>
                <Box
                  alignItems={"center"}
                  alignSelf={"center"}
                  alignContent={"center"}
                  paddingTop={"25px"}
                  textAlign={"center"}
                >
                  <Button
                    className="accept-button"
                    variant={"contained"}
                    onClick={handleOpen}
                    style={{ background: checked ? "black" : "lightgray" }}
                    disabled={!checked}
                  >
                    I accept
                  </Button>
                </Box>
              </Grid>
              <Modal
                open={thankYouModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="thankyou-modal">
                  <div
                    className="modal-content"
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="modal-body" style={{ textAlign: "center" }}>
                      <img
                        src={ThankYouIcon}
                        alt=""
                        style={{ width: "100px" }}
                      />
                      <div className="thankyou-message">
                        <Typography className="thankyou-text">
                          Thank you!
                        </Typography>
                        <Typography>
                          We appreciate you for acknowledging and accepting our
                          terms and conditions.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
            </Grid>
            <Box mt={5} className="footer">
              <Container pt={5}>
                <Grid container>
                  <Grid item md={5}>
                    <Typography
                      className="community-thrives"
                      paddingTop={"20px"}
                    >
                      Building Connected Communities.
                    </Typography>
                    <Typography>
                      <a
                        href="https://www.ekamillam.com/"
                        target="_blank"
                        title="Ekam Illam"
                        rel="noreferrer"
                      >
                        <img src={NivasiLightIcon} alt="footer-logo" />
                      </a>
                    </Typography>
                    <Typography className="rights-reserved">
                      <a
                        href="https://www.ekamillam.com/"
                        target="_blank"
                        title="Ekam Illam"
                        rel="noreferrer"
                      >
                        © <span id="year">{new Date().getFullYear()}</span> All
                        Rights Reserved, Ekam Illam Private Limited
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item md={7} paddingTop={"20px"}>
                    <Box className="support-section">
                      <Box className="support-contents">
                        <Typography>
                          Have questions? <br /> You can email our support team
                          at
                          <br />
                          <a
                            href="mailto:support@nivasiapp.com"
                            className="support-content"
                          >
                            support@nivasiapp.com
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <img
              src={FooterImage}
              alt="footer"
              style={{ width: "100%", backgroundSize: "contain" }}
            />
          </Paper>
        )
      ) : (
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Card>
      )}
    </React.Fragment>
  );
};

export default ServiceProviderTermsAndConditions;
