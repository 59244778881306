import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Modal,
  Box,
  Divider,
  Avatar,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { closeOperationalHoursModal } from "../../redux/Slices/CommonSlice";
import "./ServiceProvider.scss";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const ServiceProviderOperationalInfo = (props) => {
  const { onOkHandler, onCancelHandler, hoursValue } = props;
  const open = useSelector((store) => store.common.operationalHoursModalOpen);
  const dispatch = useDispatch();
  const [is24by7, setIs24by7] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const daysOfWeek = useMemo(
    () => [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    []
  );

  const initialDaysState = daysOfWeek.reduce((acc, day) => {
    acc[day] = { startTime: null, endTime: null };
    return acc;
  }, {});

  const [visibleDays, setVisibleDays] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {})
  );
  const [days, setDays] = useState(initialDaysState);

  const toggleDayVisibility = (day) => {
    setVisibleDays((prev) => {
      const updatedVisibleDays = {
        ...prev,
        [day]: !prev[day],
      };

      if (!updatedVisibleDays[day]) {
        setDays((prev) => {
          const updatedDays = { ...prev };
          delete updatedDays[day];
          return updatedDays;
        });

        setSelectedDays((prev) => prev.filter((val) => val !== day));

        setErrors((prev) => {
          const updatedErrors = { ...prev };
          delete updatedErrors[day];
          return updatedErrors;
        });
      } else {
        setSelectedDays((prev) => [...prev, day]);
      }

      return updatedVisibleDays;
    });
  };

  const handleTimeChange = (day, type, value) => {
    setDays((prev) => {
      const updatedDays = { ...prev };
      updatedDays[day] = {
        ...updatedDays[day],
        [type]: value ? dayjs.utc(value).local() : null,
      };

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };

        const { startTime, endTime } = updatedDays[day];

        if (startTime && endTime && dayjs(startTime).isSame(endTime)) {
          updatedErrors[day] = "Start time and end time cannot be the same.";
        } else if (
          type === "endTime" &&
          startTime &&
          dayjs(value).isBefore(startTime)
        ) {
          updatedErrors[day] =
            "The end time must be later than the start time.";
        } else if (
          type === "startTime" &&
          endTime &&
          dayjs(endTime).isBefore(value)
        ) {
          updatedErrors[day] = "The start time must be before the end time.";
        } else {
          delete updatedErrors[day];
        }

        return updatedErrors;
      });

      return updatedDays;
    });
  };

  const hasTimeErrors = Object.keys(errors).some((day) => visibleDays[day]);

  useEffect(() => {
    const disabled = selectedDays.some(
      (day) =>
        !days[day]?.startTime ||
        !days[day]?.endTime ||
        dayjs(days[day]?.startTime).isSame(days[day]?.endTime) ||
        dayjs(days[day]?.startTime).isAfter(days[day]?.endTime)
    );
    setIsButtonDisabled(disabled);
  }, [selectedDays, days]);

  const onInitDateFunction = useCallback(() => {
    if (hoursValue && hoursValue.length === 1 && hoursValue[0].day === "All") {
      setIs24by7(true);
    } else if (hoursValue && hoursValue?.length !== 0) {
      const filteredHoursValue = hoursValue.filter((item) => {
        const startTime =
          item.startTime !== null
            ? typeof item.startTime === "string"
              ? item.startTime
              : item.startTime?.$D
            : null;
        const endTime =
          item.endTime !== null
            ? typeof item.endTime === "string"
              ? item.endTime
              : item.endTime?.$D
            : null;
        return startTime && endTime;
      });
      const updatedSchedule = daysOfWeek.reduce((acc, day) => {
        const matchingDay = filteredHoursValue?.find(
          (item) => item.day === day
        );
        acc[day] = matchingDay
          ? {
              startTime: matchingDay.startTime
                ? dayjs(matchingDay.startTime)
                : null,
              endTime: matchingDay.endTime ? dayjs(matchingDay.endTime) : null,
            }
          : { startTime: null, endTime: null };
        return acc;
      }, {});
      setDays(updatedSchedule);
      const updatedVisibleDays = daysOfWeek.reduce((acc, day) => {
        const matchingDay = filteredHoursValue.find((item) => item.day === day);
        if (matchingDay) {
          setSelectedDays((prevState) => [...prevState, day]);
        }
        acc[day] = !!matchingDay;
        return acc;
      }, {});
      setVisibleDays(updatedVisibleDays);
    }
  }, [
    hoursValue,
    daysOfWeek,
    setIs24by7,
    setDays,
    setVisibleDays,
    setSelectedDays,
  ]);

  useEffect(() => {
    if (open) {
      onInitDateFunction();
    }
  }, [open, onInitDateFunction]);

  const handleSubmit = () => {
    let hasErrors = false;
    const updatedErrors = {};

    Object.keys(days).forEach((day) => {
      const { startTime, endTime } = days[day];
      if (visibleDays[day] && (!startTime || !endTime)) {
        hasErrors = true;
        updatedErrors[day] = "Please select both start and end times.";
      }
    });

    if (hasErrors) {
      setErrors(updatedErrors);
      return;
    }

    if (is24by7) {
      const updatedDays = [{ day: "All", startTime: null, endTime: null }];
      setDays(updatedDays);
      onOkHandler(updatedDays);
      dispatch(closeOperationalHoursModal());
    } else {
      const result = Object.entries(days).map(([day, times]) => ({
        day,
        startTime: dayjs.utc(times.startTime).local() || null,
        endTime: dayjs.utc(times.endTime).local() || null,
      }));
      onOkHandler(result);
      dispatch(closeOperationalHoursModal());
    }
  };

  const copyTimes = () => {
    if (!selectedDays.length) return;
    let sourceStartTime = null;
    let sourceEndTime = null;

    for (const day of selectedDays) {
      if (days[day]?.startTime && sourceStartTime === null) {
        sourceStartTime = days[day].startTime;
      }
      if (days[day]?.endTime && sourceEndTime === null) {
        sourceEndTime = days[day].endTime;
      }
      if (sourceStartTime && sourceEndTime) break;
    }

    if (!sourceStartTime && !sourceEndTime) return;

    setDays((prevDays) => {
      const updatedDays = { ...prevDays };
      selectedDays.slice(1).forEach((targetDay) => {
        updatedDays[targetDay] = {
          startTime: sourceStartTime,
          endTime: sourceEndTime,
        };
      });
      return updatedDays;
    });
  };

  const handleSelectWeekdays = () => {
    const newDays = daysOfWeek.slice(0, 5);
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});
      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });
      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const handleSelectWeekends = () => {
    const newDays = daysOfWeek.slice(5);
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});

      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });

      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const handleSelectAll = () => {
    const newDays = daysOfWeek;
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});

      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });

      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const cancelHandler = () => {
    setErrors({});
    onCancelHandler();
    dispatch(closeOperationalHoursModal());
    setDays({});
    setVisibleDays({});
    setIs24by7(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={cancelHandler}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "60%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          padding: "20px",
          borderRadius: "8px",
          bgcolor: "background.paper",
          boxShadow: 24,
        }}
      >
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="h5"
              align="center"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Business Hours
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={is24by7}
                    onChange={() => {
                      setIs24by7(!is24by7);
                      setSelectedDays([]);
                      setVisibleDays({});
                      setDays({});
                    }}
                    color="primary"
                  />
                }
                label="24/7"
              />
            </Grid>
            {!is24by7 && (
              <>
                <Stack direction="row" spacing={1} justifyContent="center">
                  {daysOfWeek.map((day) => (
                    <Avatar
                      key={day}
                      onClick={() => toggleDayVisibility(day)}
                      sx={{
                        bgcolor: visibleDays[day] ? "#003350" : "grey",
                        cursor: "pointer",
                      }}
                    >
                      {day.charAt(0).toUpperCase()}
                    </Avatar>
                  ))}
                </Stack>
                <Divider sx={{ my: 2 }} />
                {daysOfWeek.map(
                  (day) =>
                    visibleDays[day] && (
                      <Grid key={day} container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                          <TimePicker
                            label={`${day} Start Time`}
                            value={
                              days[day]?.startTime
                                ? dayjs(days[day].startTime)
                                : null
                            }
                            onChange={(newValue) =>
                              handleTimeChange(day, "startTime", newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={!!errors[day]}
                                helperText={errors[day] || " "}
                              />
                            )}
                            sx={{ width: "100%" }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            label={`${day} End Time`}
                            value={
                              days[day]?.endTime
                                ? dayjs(days[day].endTime)
                                : null
                            }
                            onChange={(newValue) =>
                              handleTimeChange(day, "endTime", newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={!!errors[day]}
                                helperText={errors[day] || " "}
                              />
                            )}
                            sx={{ width: "100%" }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </Grid>
                        {errors[day] && (
                          <Grid item xs={12}>
                            <Typography color="error">{errors[day]}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    )
                )}
              </>
            )}

            <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
              {!is24by7 && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={copyTimes}
                        disabled={!selectedDays.length || hasTimeErrors}
                      >
                        Copy Times
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectAll}
                        disabled={hasTimeErrors}
                      >
                        Select All
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectWeekdays}
                        disabled={hasTimeErrors}
                      >
                        Weekdays
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectWeekends}
                        disabled={hasTimeErrors}
                      >
                        Weekends
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: "20px",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={cancelHandler}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!is24by7 && isButtonDisabled}
                    >
                      Save Schedule
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </>
      </Box>
    </Modal>
  );
};

export default ServiceProviderOperationalInfo;
