import React from "react";
import Layout from "../../components/Layout/Layout";
import Generate from "../../components/PrintPin/Generate";
const GeneratePin = () => {
  return (
    <Layout>
      <Generate />
    </Layout>
  );
};

export default GeneratePin;
