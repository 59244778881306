import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  ListItemText,
} from "@mui/material";
import Loader from "../Common/Loader";
import {
  AUTO,
  BLACK,
  BOLD,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  LEFT,
} from "../../utils/constants";
import { Formik } from "formik";
import "../Common/Common.scss";
import { printPin } from "../../services/print-pin";
import { OpenPdfViewer } from "../../helpers/OpenPdfViewer";

const Generate = () => {
  return (
    <Formik
      initialValues={{
        count: 0,
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit, handleBlur }) => {
        const printCodeHandler = async () => {
          const response = await printPin(values.count);
          OpenPdfViewer(
            "Generate Multiple Verification Pin Only",
            response.data.data,
            ""
          );
        };
        const printPdfHandler = async () => {
          const response = await printPin(values.count);
          OpenPdfViewer(
            "Generate Multiple Verification Pin Full",
            response.data.data,
            ""
          );
        };
        return (
          <Paper
            className="paper__container"
            sx={{ height: AUTO }}
            elevation={4}
          >
            <form noValidate onSubmit={handleSubmit} className="form">
              <Typography key="3" color={BLACK}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ color: DEEP_OCEAN_BLUE, fontWeight: BOLD }}
                    >
                      Generate Welcome Letter
                    </Typography>
                  }
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid container marginTop={10}>
                  <Grid item xs={6} marginLeft={30} marginTop={3}>
                    <Grid
                      container
                      alignItems={CENTER}
                      display={"flex"}
                      spacing={1}
                      justifyContent={CENTER}
                      justifyItems={CENTER}
                    >
                      <Grid item xs={6} justifyItems={CENTER}>
                        <Typography
                          sx={{ color: DEEP_OCEAN_BLUE, fontWeight: BOLD }}
                        >
                          Number of letters to be generated
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          placeholder="Enter the pin count"
                          fullWidth
                          type="number"
                          size={"large"}
                          value={values?.count === 0 ? " " : values.count}
                          onChange={handleChange}
                          name="count"
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                              {
                                WebkitAppearance: "none",
                                margin: 0,
                              },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container marginTop={5} spacing={2}>
                  {values.count > 100 && (
                    <Grid item md={6} display={FLEX} justifyContent={CENTER}>
                      <span className="form-note-label">
                        Note: Only upto 100 letters can be generated at a time.
                      </span>
                    </Grid>
                  )}
                  <Grid item md={6} display={FLEX} justifyContent={"right"}>
                    {values.count > 0 && values.count <= 100 && (
                      <Button
                        type="button"
                        variant="contained"
                        className="submit__button"
                        onClick={printPdfHandler}
                        from="Full Pdf"
                      >
                        Print full pdf
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={6} display={FLEX} justifyContent={LEFT}>
                    {values.count > 0 && values.count <= 100 && (
                      <Button
                        type="button"
                        variant="contained"
                        className="submit__button"
                        onClick={printCodeHandler}
                        from="Only Pin Pdf"
                      >
                        Print code
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Loader />
            </form>
          </Paper>
        );
      }}
    </Formik>
  );
};

export default Generate;
