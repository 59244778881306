import React, { useCallback, useEffect, useRef, useState } from "react";
import ReportsTable from "../Common/ReportsTable";
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Backdrop,
  Button,
  Breadcrumbs,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  ALL,
  BLACK,
  DEEP_OCEAN_BLUE,
  FLEX,
  FLEX_END,
  SMALL,
  START,
} from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { fetchResidentReport } from "../../services/reports";
import { fetchBuildingForCommunity } from "../../services/building";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { reportsDatePickerStyle } from "../Common/CommonStyles";
import loader from "../../assets/images/Loader.gif";
import {
  formatDate,
  fromDateHandler,
  toDateHandler,
} from "../../helpers/DatePickerValues";
import Referrals from "./components/Referrals";
import { formatPhoneNumber } from "../../helpers/formatValues";
import { residentEngagementCsvReport } from "../../services/csvReport";

const ResidentEngagement = () => {
  const initialFilterValues = {
    from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
    to: moment(dayjs().$d).format("DD-MM-YYYY"),
    building: [ALL],
  };
  const [residents, setResidents] = useState([]);
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("");
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const rowsPerPageRef = useRef(rowsPerPage);
  const [buildings, setBuildings] = useState([]);
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const [residentId, setResidentId] = React.useState(null);

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/reports/all"}
      className="breadcrumb__link"
    >
      Reports
    </Link>,
    <Typography key="3" color={BLACK}>
      Resident Engagement
    </Typography>,
  ];

  useEffect(() => {
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const fetchData = useCallback(
    async (pageVal) => {
      dispatch(startLoading());
      const response = await fetchResidentReport(
        pageVal || 0,
        rowsPerPage,
        searchValuesRef.current,
        filterValuesRef.current.from,
        filterValuesRef.current.to,
        filterValuesRef.current.building,
        sortColumn,
        sortBy
      );
      if (response.success && response.data?.residents) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setResidents(response?.data?.residents);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [rowsPerPage, dispatch, sortColumn, sortBy]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, filterValues]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const fetchresidents = async () => {
      setPage(0);
      dispatch(startLoading());
      const response = await fetchResidentReport(
        0,
        rowsPerPageRef.current,
        searchValue,
        filterValuesRef.current.from,
        filterValuesRef.current.to,
        filterValuesRef.current.building
      );
      if (response.success && response.data?.residents) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setResidents(response?.data?.residents);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchresidents();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const formattedFromDate = formatDate(filterValues.from);
  const formattedToDate = formatDate(filterValues.to);

  useEffect(() => {
    (async () => {
      const response = await fetchBuildingForCommunity(1);
      if (response.success && response.data) {
        setBuildings(response.data);
      } else {
        setBuildings([]);
      }
    })();
  }, []);

  const clearHandler = () => {
    setFilterValues(() => ({
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
      building: [ALL],
    }));
    setSearchValue("");
  };

  const loading = useSelector((state) => state.common.loading);

  const renderReviews = (data) => {
    if (data.reviews > 0) {
      return (
        <Link
          target="_blank"
          key="1"
          color={DEEP_OCEAN_BLUE}
          to={`/admin/reports/resident-reviews/${data.id}?from=${
            filterValuesRef.current.from
          }&to=${filterValuesRef.current.to}&resident=${
            data?.firstName + " " + data?.lastName
          }`}
        >
          {data.reviews}
        </Link>
      );
    } else {
      return <span>{data.reviews}</span>;
    }
  };

  const renderReferals = (data) => {
    return (
      <span
        className={data.referrals > 0 ? "cursor_pointer text_underline" : ""}
        onClick={() => {
          if (data.referrals > 0) {
            setResidentId(data.id);
          }
        }}
      >
        {data.referrals}
      </span>
    );
  };

  const downloadHandler = async () => {
    const response = await residentEngagementCsvReport(
      searchValuesRef.current,
      filterValuesRef.current.from,
      filterValuesRef.current.to,
      filterValuesRef.current.building,
      sortColumn,
      sortBy
    );
    if (response.data) {
      const fileName = "Resident-engagement-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={residentId ? true : false}
        maxWidth={"md"}
        onClose={() => setResidentId(null)}
      >
        <DialogTitle>Referrals</DialogTitle>
        <DialogContent>
          <Referrals
            residentId={residentId}
            from={filterValues.from}
            to={filterValues.to}
          />
        </DialogContent>
      </Dialog>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="container" elevation={4}>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <OutlinedInput
              fullWidth
              placeholder="Search by name, apartment no, building name"
              size={SMALL}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              startAdornment={
                <InputAdornment position={START}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item md={6} marginTop={"-10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="From"
                  format="DD/MM/YYYY"
                  value={dayjs(formattedFromDate)}
                  disableFuture
                  onChange={(newFromValue) =>
                    fromDateHandler(newFromValue, setFilterValues)
                  }
                />
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="To"
                  format="DD/MM/YYYY"
                  value={dayjs(formattedToDate)}
                  minDate={dayjs(formattedFromDate)}
                  onChange={(newToValue) =>
                    toDateHandler(newToValue, setFilterValues)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              size="small"
              disableCloseOnSelect
              multiple
              options={[
                ALL,
                ...filterValues?.building.filter(
                  (selectedValue) => selectedValue !== ALL
                ),
                ...buildings
                  .map((building) => building.id)
                  .filter(
                    (option) =>
                      !filterValues?.building.includes(option) && option !== ALL
                  ),
              ]}
              getOptionLabel={(option) => {
                const building = [{ id: ALL, name: ALL }, ...buildings].find(
                  (s) => s.id === option
                );
                return building?.name || "";
              }}
              value={filterValues?.building}
              onChange={(_, newValue) => {
                let event = {
                  target: {
                    value: newValue,
                  },
                };
                handleInputChange(
                  event,
                  "building",
                  setFilterValues,
                  filterValues,
                  () => {},
                  ALL
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Building" name="building" />
              )}
              renderTags={(selected, getTagProps) => {
                const displayedTags = selected.slice(0, 3);
                const hiddenTagsCount = selected.length - displayedTags.length;
                return (
                  <Box display="flex" alignItems="center">
                    {displayedTags.map((value, index) => (
                      <Chip
                        size="small"
                        key={value}
                        label={
                          value === ALL
                            ? ALL
                            : buildings.find(
                                (building) => building.id === value
                              )?.name
                        }
                        {...getTagProps({ index })}
                      />
                    ))}
                    {hiddenTagsCount > 0 && (
                      <Box ml={1}>+{hiddenTagsCount}</Box>
                    )}
                  </Box>
                );
              }}
            />
          </Grid>
          <Grid item md={6} display={FLEX} justifyContent={FLEX_END}>
            <Grid marginRight={2}>
              <Button
                variant="contained"
                className="submit__button"
                onClick={clearHandler}
              >
                Clear All
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                className="submit__button"
                onClick={downloadHandler}
              >
                Download CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ReportsTable
          from="residentsList"
          headers={[
            { label: "Full Name" },
            { label: "Apt. No" },
            { label: "Building" },
            { label: "Phone", width: "14%" },
            { label: "Referrals", isSortable: true, sortKey: "Referral" },
            { label: "Phone# Taps", isSortable: true, sortKey: "Phone taps" },
            { label: "Reviews", isSortable: true, sortKey: "Review" },
            { label: "Helpful Tags", isSortable: true, sortKey: "Helpful" },
            { label: "Flags", isSortable: true, sortKey: "Flags" },
          ]}
          data={residents?.map((row) => {
            return {
              id: row?.id,
              commonColumns: [
                row?.firstName ? row?.firstName + " " + row?.lastName : "-",
                row?.apartmentNo,
                row?.buildingName,
                row?.phone
                  ? formatPhoneNumber(row?.phone, row?.countryCode)
                  : "-",
                renderReferals(row),
                row?.phoneTaps,
                renderReviews(row),
                row?.helpful,
                row?.flags,
              ],
              data: row,
            };
          })}
          sortOrderHandler={sortOrderHandler}
          sortBy={sortBy}
          sortColumn={sortColumn}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default ResidentEngagement;
