import { pdf } from "@react-pdf/renderer";
import PinPdf from "../utils/templates/PinPdf";
import PinPdfList from "../utils/templates/PinPdfList";
import TermsConditionsPdf from "../utils/templates/TermsConditionsPdf";
import OnlyPinPdf from "../utils/templates/OnlyPinPdf";
import OnlyPinPdfList from "../utils/templates/OnlyPinPdfList";
import TermsConditionsAcknowledgedPdf from "../utils/templates/TermsConditionsAcknowledgedPdf";

export const OpenPdfViewer = async (title, content, values) => {
  // Generate the PDF blob
  const blob = await pdf(
    content?.resident && title === "Resident Verification Pin Full" ? (
      <PinPdf resident={content} />
    ) : content?.resident && title === "Resident Verification Pin Only" ? (
      <OnlyPinPdf resident={content} />
    ) : title === "Terms & Conditions Acknowledged" ? (
      <TermsConditionsAcknowledgedPdf
        serviceProvider={content}
        language={values}
      />
    ) : title === "Terms & Conditions" ? (
      <TermsConditionsPdf serviceProvider={content} language={values} />
    ) : title === "Generate Multiple Verification Pin Full" ? (
      <PinPdfList generatedPins={content || []} />
    ) : (
      <OnlyPinPdfList generatedPins={content || []} />
    )
  ).toBlob();

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  if (title === "Terms & Conditions Acknowledged") {
    // Download the PDF file directly
    const link = document.createElement("a");
    link.href = url;
    link.download = `${content?.fileName}.pdf`; // Set the file name
    link.click();
    // Attempt to close the current tab
    window.close();
  } else {
    // Open a new tab and set up the PDF viewer for other titles
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.document.title = title;
      newTab.document.body.style.margin = 0;

      // Create an iframe to display the PDF
      const iframe = newTab.document.createElement("iframe");
      iframe.style.width = "100%";
      iframe.style.height = "100vh";
      iframe.src = url;

      newTab.document.body.appendChild(iframe);
    }
  }
};
